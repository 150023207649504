import {
  Button,
  Typography,
  SNACKBAR_TYPES,
  CircleButton,
  useFlash,
} from "@lysaab/ui-2";
import { useState } from "react";
import "./NpsModalContainer.scss";
import { Modal } from "../../../../components/modal/Modal";
import { TranslatedText } from "../../../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import sad from "./images/sad.svg";
import happy from "./images/happy.svg";
import heart from "./images/heart.svg";
import love from "./images/love.svg";
import { useYearlyReviewStatus } from "../../../../hooks/useYearlyReviewState";
import { YearlyReviewStatus } from "../../../../data/dataYearlyReview";
import {
  Answer,
  Dismission,
  useSendAnswer,
  useSendDismission,
  useGetSurvey,
} from "@lysaab/shared";

const messages = defineMessages({
  success: {
    id: "nps.message.snackbar.success",
  },
});

export function NpsModal() {
  const pushFlash = useFlash();
  const { status } = useYearlyReviewStatus();
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = useState(-1);
  const intl = useIntl();
  const { data, isError, isPending } = useGetSurvey("WEB");
  const { mutate: sendAnswer } = useSendAnswer("WEB");
  const { mutate: sendDismission } = useSendDismission("WEB");

  if (isPending || isError || status !== YearlyReviewStatus.NOT_NEEDED) {
    return null;
  }
  if (data === null) {
    return null;
  }

  const close = (npsId: number) => {
    setIsOpen(false);
    if (!isOpen) {
      handleDismiss(npsId);
    }
  };

  const handleDismiss = (npsId: number) => {
    const dismissionData: Dismission = { npsId: npsId };
    sendDismission(dismissionData);
  };
  return (
    <Modal
      showModal={isOpen}
      closeOnOverlayClick
      hideHeader
      onClose={() => close(data.npsId)}
      width={460}
      hideScrollTopButton={true}
      data-test-id="nps-modal"
    >
      <div className="npsModalContainer">
        <div className="iconWrapper">
          <CircleButton
            className="closeModalButton"
            ariaLabel="Increment"
            onClick={() => close(data.npsId)}
            icon="Cancel"
            data-test-id="close-nps-modal-button"
          />
          {Icon(selected)}
        </div>
        <Typography type="h3">
          <TranslatedText id="nps.question" />
        </Typography>

        <RatingsButtons selected={selected} setSelected={setSelected} />
        <div className="npsScale">
          <div className="scaleText text-left">
            <TranslatedText id="nps.scale.bottom" />
          </div>
          <div className="scaleText text-right">
            <TranslatedText id="nps.scale.top" />
          </div>
        </div>
        <Button
          size="medium"
          label={intl.formatMessage({ id: "nps.done.button" })}
          onClick={() => {
            const answerData: Answer = {
              npsId: data.npsId,
              npsRating: selected,
            };
            sendAnswer(answerData);
            setIsOpen(false);
            pushFlash({
              text: intl.formatMessage(messages.success),
              type: SNACKBAR_TYPES.SUCCESS,
              timer: 1500,
            });
          }}
          block
          disabled={selected === -1 ? true : false}
        />
      </div>
    </Modal>
  );
}

interface RatingsButtonsProps {
  selected: number;
  setSelected: React.Dispatch<React.SetStateAction<number>>;
}

function RatingsButtons({ selected, setSelected }: RatingsButtonsProps) {
  const buttonsArray = Array.from({ length: 11 }, (_, index) => index);

  return (
    <div className="npsRating">
      {buttonsArray.map((id) => (
        <Button
          key={id}
          className="npsRatingButton"
          size="small"
          variant={selected >= id ? "primary" : "secondary"}
          label={String(id)}
          onClick={() => setSelected(id)}
        ></Button>
      ))}
    </div>
  );
}

function Icon(rating: number) {
  if (rating === -1) {
    return <img src={heart} alt="Heart" />;
  } else if (rating <= 6) {
    return <img src={sad} alt="Sad" />;
  } else if (rating <= 8) {
    return <img src={happy} alt="Happy" />;
  } else if (rating <= 10) {
    return <img src={love} alt="Love" />;
  }
}
